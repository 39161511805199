import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/unittestbot.github.io/unittestbot.github.io/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "introduction"
    }}>{`Introduction`}</h1>
    <h2 {...{
      "id": "what-is-unittestbot"
    }}>{`What is UnitTestBot?`}</h2>
    <p>{`UnitTestBot (aka UTBot) tool provides engineers with a simple 1-mouse-click way to automatically generate unit tests and
visualize coverage for projects written in C and C++ Programming Languages. It is an IDE extension (the client) paired
with server application.`}</p>
    <p>{`The tool uses `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Symbolic_execution"
      }}>{`symbolic execution`}</a>{` for test cases generation. UTBot
uses KLEE Symbolic Virtual Machine enchanced with a new advanced bidirectional symbolic execution algorithm developed by
Research Group from Saint Petersburg State University. Tests are generated in form
of `}<a parentName="p" {...{
        "href": "https://github.com/google/googletest"
      }}>{`Google Test Framework`}</a>{`.`}</p>
    <h2 {...{
      "id": "tests-generation-regression-suite"
    }}>{`Tests Generation: Regression Suite`}</h2>
    <p><strong parentName="p">{`UnitTestBot`}</strong>{` generates test cases by code, trying to cover maximum statements and execution paths. We treat source
code as `}<em parentName="p">{`source of truth`}</em>{` assuming that behavior is correct (corresponds to initial user demand). Generated tests are
placed in the so-called `}<strong parentName="p"><em parentName="strong">{`regression suite`}</em></strong>{`. Thus, we `}<em parentName="p">{`fixate`}</em>{` current behavior by test cases.`}</p>
    <p>{`What is the purpose? You obtain `}<strong parentName="p">{`full control`}</strong>{` of future code changes. Any code change now `}<strong parentName="p">{`can't break behavior
silently`}</strong>{`. If developer changes the code, then some tests will fall on CI. Then, ther are two possible scenarios:`}</p>
    <ul>
      <li parentName="ul">{`Initial behavior was `}<span style={{
          "color": "green"
        }}><b>{`correct`}</b></span>{` but developer `}<i>{`broke`}</i>{` it. And, as a result,
generated test helped to find a bug.`}</li>
      <li parentName="ul">{`Initial behavior was `}<span style={{
          "color": "red"
        }}><b>{`incorrect`}</b></span>{` and developer `}<i>{`fixed`}</i>{` it. Then, test must
be manually updated to fixate new behavior.`}</li>
    </ul>
    <p>{`UTBot provides dramatic code quality improvement in the long term: after tests are generated no change can break
behavior without an alarm.`}</p>
    <h2 {...{
      "id": "test-generation-errors-detection"
    }}>{`Test Generation: Errors Detection`}</h2>
    <p>{`UnitTestBot is not only able to fixate the behavior in an existing code, but it also helps to find real bugs in your
project. Some code fragments might be invalid, while developers don't know about it. For example, buffer overflows,
assertion failures, segmentation faults, and so on. If UTBot finds an execution path leading to such a situation, it
creates a special test case that is added to the so-called `}<strong parentName="p"><em parentName="strong">{`error suite`}</em></strong>{`.`}</p>
    <p>{`While all tests from `}<em parentName="p">{`regression suite`}</em>{` are `}<em parentName="p">{`green`}</em>{` right after generation, tests from `}<em parentName="p">{`error suite`}</em>{` are `}<em parentName="p">{`red`}</em>{` (they
fail if you run them). Such tests generated by UnitTestBot are a convenient way to discover critical problems as soon as
possible. After developer fixes a bug, tests can be rerun to verify the problem is gone.`}</p>
    <h2 {...{
      "id": "code-coverage"
    }}>{`Code Coverage`}</h2>
    <p>{`UTBot tries to generate test cases that:`}</p>
    <ul>
      <li parentName="ul">{`maximize `}<a href="https://en.wikipedia.org/wiki/Code_coverage">{`code coverage`}</a>{` (by statements and branches);`}</li>
      <li parentName="ul">{`minimize number of tests, i.e. if you drop any test case from generated suite, your code coverage decreases.`}</li>
    </ul>
    <p>{`Of course, in an ideal world we would like to test `}<strong parentName="p">{`every`}</strong>{` execution path of the program (so-called `}<em parentName="p">{`path coverage`}</em>{`),
but the number of such paths is often infinite due to loops and recursion. UnitTestBot aims to maximize other types of
coverages: `}<strong parentName="p"><em parentName="strong">{`statement coverage`}</em></strong>{`
and `}<strong parentName="p"><em parentName="strong">{`branch coverage`}</em></strong>{`, most commonly used as metrics for coverage quality assessment tools.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      